import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { TPgRateAgeType } from 'entities/Age/types';
import { EAudioBookFormat, ETTSVoiceName } from 'entities/AudioBook/constants';
import type { EAudioLawStatus } from 'entities/AudioBook/types';
import type { ELicence } from 'entities/BookCard/constants';
import { ECoverTypes } from 'entities/Cover/types';
import type { IEmployee } from 'entities/Employee/types';
import type { IDownloadInProgressFile } from 'entities/File/types';
import type { IGenre } from 'entities/Genre/types';
import type { ITag } from 'entities/Tag/types';
import { HYDRATE } from 'next-redux-wrapper';

import type { IHydrateAction } from 'sp-redux';
import type { IAudioBookMeta } from 'sp-redux/slices/audioBookMeta/types';
import { getAudioBookAgreementFilesThunk } from 'sp-redux/thunks/audioBooks/getAudioBookAgreementFiles';
import { getAudioBookAudioFilesThunk } from 'sp-redux/thunks/audioBooks/getAudioBookAudioFiles';
import { getAudioBookCoverThunk } from 'sp-redux/thunks/audioBooks/getAudioBookCoverThunk';
import { getAudioBookEmployeesByAudioIdThunk } from 'sp-redux/thunks/audioBooks/getAudioBookEmployeesByAudioId';
import { getAudioBookMetaByAudioBookIdThunk } from 'sp-redux/thunks/audioBooks/getAudioBookMetaByAudioId';
import { getAudioBookMetaByBookIdThunk } from 'sp-redux/thunks/audioBooks/getAudioBookMetaByBookId';
import { getAudioBookMetaInAudioPublicationMasterThunk } from 'sp-redux/thunks/audioBooks/getAudioBookMetaInAudioPublicationMaster';
import { getAudioBookModerationCommentsThunk } from 'sp-redux/thunks/audioBooks/getAudioBookModerationCommentsThunk';
import { setAudioBookCoverThunk } from 'sp-redux/thunks/audioBooks/setAudioBookCoverThunk';

import { dndFilesSorting } from 'utils/dndFilesSorting';

const initialState: IAudioBookMeta = {
  isFetching: true,
  isFetched: false,
  meta: {},
  audioFiles: [],
  proofDocuments: [],
  // Забираем из отдельного АПИ. Спойлер: его нет.
  cover: {},
  moderationComments: [],
  employees: [],
  isCoverLoading: false,
  hasAudioResponsibilityAcceptanceConflicts: false,
};

const AudioBookMetaSlice = createSlice({
  name: 'audioBookMeta',
  initialState,
  reducers: {
    setAudioBookTitle: (state, action: PayloadAction<string>) => {
      state.meta.book_name = action.payload;
    },

    setAudioBookFormat: (state, action: PayloadAction<EAudioBookFormat>) => {
      state.meta.format = action.payload;
    },

    setAudioBookAnnotation: (state, action: PayloadAction<string>) => {
      state.meta.annotation = action.payload;
    },

    setAudioBookAge: (state, action: PayloadAction<TPgRateAgeType>) => {
      state.meta.age = action.payload;
    },

    setAudioBookObsceneLanguage: (state, action: PayloadAction<boolean>) => {
      state.meta.obscene_language = action.payload;
    },

    setAudioBookLgbtMark: (state, action: PayloadAction<boolean>) => {
      state.meta.lgbt_mark = action.payload;
    },

    setAudioBookLgbtRulesAccepted: (state, action: PayloadAction<boolean>) => {
      state.meta.lgbt_rules_accepted = action.payload;
    },

    setAudioBookLgbtConflicts: (state, action: PayloadAction<boolean>) => {
      state.meta.hasLgbtConflict = action.payload;
    },

    setAudioBookTags: (state, action: PayloadAction<ITag[]>) => {
      state.meta.tags = action.payload;
    },

    setAudioBookGenres: (state, action: PayloadAction<IGenre[]>) => {
      state.meta.genres = action.payload;
    },

    setAudioBookLicense: (state, action: PayloadAction<ELicence>) => {
      state.meta.license_type = action.payload;
    },

    setAudioBookPrice: (state, action: PayloadAction<string>) => {
      state.meta.price = action.payload;
    },

    setAudioBookLawStatus: (state, action: PayloadAction<EAudioLawStatus>) => {
      state.meta.phonogram_legal_status = action.payload;
    },

    setAudioBookReadersName: (state, action: PayloadAction<string>) => {
      state.meta.reader = action.payload;
    },

    setAudioBookAddProofDocument: (
      state,
      action: PayloadAction<IDownloadInProgressFile>,
    ) => {
      state.proofDocuments = [...state.proofDocuments, action.payload];
    },

    deleteAudioBookProofDocument: (
      state,
      action: PayloadAction<IDownloadInProgressFile>,
    ) => {
      state.proofDocuments = state.proofDocuments.filter(
        doc => doc.id !== action.payload.id,
      );
    },

    addAudioFile: (state, action: PayloadAction<IDownloadInProgressFile>) => {
      state.audioFiles = [...state.audioFiles, action.payload];
    },

    reorderAudioFiles: (
      state,
      action: PayloadAction<IDownloadInProgressFile[]>,
    ) => {
      state.audioFiles = action.payload;
    },

    deleteAudioFile: (
      state,
      action: PayloadAction<IDownloadInProgressFile>,
    ) => {
      state.audioFiles = state.audioFiles.filter(
        file => file.id !== action.payload.id,
      );
    },

    setAudioBookEmployees: (state, action: PayloadAction<IEmployee[]>) => {
      state.employees = action.payload;
    },

    setAudioBookCoverLoading: (state, action: PayloadAction<boolean>) => {
      state.isCoverLoading = action.payload;
    },

    setIsAudioCoverFromConstructor: (state, action: PayloadAction<boolean>) => {
      state.cover.is_constructor = action.payload;
    },

    setAudioResponsibilityAccepted: (state, action: PayloadAction<boolean>) => {
      state.meta.agreed_with_audio_responsibility = action.payload;
    },

    setAudioBookHasAudioResponsibilityAcceptanceConflicts: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.hasAudioResponsibilityAcceptanceConflicts = action.payload;
    },

    setAudioBookMusicalFragments: (state, action: PayloadAction<boolean>) => {
      state.meta.has_musical_fragments = action.payload;
    },

    setGenderVoice: (state, action: PayloadAction<ETTSVoiceName>) => {
      state.meta.gender_voice = action.payload;
    },

    setGlossary: (state, action: PayloadAction<string>) => {
      state.meta.glossary = action.payload;
    },

    setCoverType: (state, action: PayloadAction<ECoverTypes>) => {
      state.cover.cover_type = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getAudioBookMetaByBookIdThunk.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(
      getAudioBookMetaByBookIdThunk.fulfilled,
      (state, action) => {
        state.isFetched = true;
        state.isFetching = false;
        state.meta = action.payload.result;
      },
    );

    builder.addCase(getAudioBookMetaByAudioBookIdThunk.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(
      getAudioBookMetaByAudioBookIdThunk.fulfilled,
      (state, action) => {
        state.isFetched = true;
        state.isFetching = false;
        state.meta = action.payload.result;
        state.meta.tags = action.payload.result.tags.filter(
          tag => tag.name !== 'Самиздат',
        );
        state.meta.annotation = action.payload.result.annotation.replace(
          /(<([^>]+)>)/gi,
          '',
        );
      },
    );

    builder.addCase(getAudioBookAudioFilesThunk.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(getAudioBookAudioFilesThunk.fulfilled, (state, action) => {
      state.isFetched = true;
      state.isFetching = false;

      state.audioFiles = dndFilesSorting(action.payload.result);
    });

    builder.addCase(getAudioBookAgreementFilesThunk.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(
      getAudioBookAgreementFilesThunk.fulfilled,
      (state, action) => {
        state.isFetched = true;
        state.isFetching = false;
        state.proofDocuments = action.payload.result;
      },
    );

    builder.addCase(
      getAudioBookMetaInAudioPublicationMasterThunk.pending,
      state => {
        state.isFetching = true;
      },
    );

    builder.addCase(
      getAudioBookMetaInAudioPublicationMasterThunk.fulfilled,
      (state, action) => {
        state.isFetched = true;
        state.isFetching = false;
        state.meta = action.payload.result;
      },
    );

    builder.addCase(getAudioBookCoverThunk.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(getAudioBookCoverThunk.fulfilled, (state, action) => {
      state.isFetched = true;
      state.isFetching = false;
      state.cover = action.payload.result;
    });

    builder.addCase(setAudioBookCoverThunk.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(setAudioBookCoverThunk.fulfilled, (state, action) => {
      state.isFetched = true;
      state.isFetching = false;
      state.cover = action.payload.result || {};
    });

    builder.addCase(getAudioBookEmployeesByAudioIdThunk.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(
      getAudioBookEmployeesByAudioIdThunk.fulfilled,
      (state, action) => {
        state.isFetched = true;
        state.isFetching = false;
        if (action.payload?.result) {
          state.employees = action.payload.result;
        }
      },
    );

    builder.addCase(getAudioBookModerationCommentsThunk.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(
      getAudioBookModerationCommentsThunk.fulfilled,
      (state, action) => {
        state.isFetched = true;
        state.isFetching = false;
        // Временное решение так как пока модераторы могут прислать только один коммент
        if (action.payload.result.comment) {
          state.moderationComments = [action.payload.result.comment];
        }
      },
    );
    builder.addCase(
      HYDRATE,
      (state, action: IHydrateAction<IAudioBookMeta>) => {
        return action.payload.audioBookMeta;
      },
    );
  },
});

export const {
  setAudioBookTitle,
  setAudioBookFormat,
  setAudioBookLicense,
  setAudioBookPrice,
  setAudioBookLawStatus,
  setAudioBookReadersName,
  setAudioBookAddProofDocument,
  deleteAudioBookProofDocument,
  deleteAudioFile,
  addAudioFile,
  reorderAudioFiles,
  setAudioBookAnnotation,
  setAudioBookEmployees,
  setAudioBookLgbtRulesAccepted,
  setAudioBookLgbtConflicts,
  setAudioBookAge,
  setAudioBookObsceneLanguage,
  setAudioBookLgbtMark,
  setAudioBookTags,
  setAudioBookGenres,
  setAudioBookCoverLoading,
  setIsAudioCoverFromConstructor,
  setAudioResponsibilityAccepted,
  setAudioBookMusicalFragments,
  setAudioBookHasAudioResponsibilityAcceptanceConflicts,
  setGenderVoice,
  setGlossary,
  setCoverType,
} = AudioBookMetaSlice.actions;

export const audioBookMetaReducer = AudioBookMetaSlice.reducer;
