import { createAsyncThunk } from '@reduxjs/toolkit';

import { doFetch } from 'utils/doFetch';

export const getPersonsThunk = createAsyncThunk(
  'persons/getPersons',
  async () => {
    const apiPointName = 'getPersonsList';

    return await doFetch<typeof apiPointName>({
      apiPointName,
    });
  },
);
