import { createAsyncThunk } from '@reduxjs/toolkit';

import type { TAppState } from 'sp-redux';
import type {
  IBookMetaType,
  ISavableBookMetaType,
} from 'sp-redux/slices/bookMeta/types';

import { doFetch } from 'utils/doFetch';
import { enrichBookMetaAnnotationWithParagraph } from 'utils/enrichBookMetaAnnotationWithParagraph';
import { mapFromAutocompleteItemsToEntityIdsList } from 'utils/mapToAutocompleteItems';

export const saveBookMetaFieldsThunk = createAsyncThunk(
  'bookMeta/saveAllMeta',
  async (fields: (keyof IBookMetaType)[], { getState }) => {
    const apiPointName = 'saveBookMeta';
    const state = getState() as TAppState;
    const { meta } = state.bookMeta;
    const body: Partial<ISavableBookMetaType> = {};
    fields.forEach(field => {
      if (field === 'genres' || field === 'tags') {
        return Object.assign(body, {
          [field]: mapFromAutocompleteItemsToEntityIdsList(meta[field] || []),
        });
      }

      return Object.assign(body, { [field]: meta[field] });
    });

    if (state.bookMeta.isFetched) {
      const { id } = state.bookMeta.meta;
      return await doFetch<typeof apiPointName>({
        apiPointName,
        urlParams: { bookId: String(id) },
        body: enrichBookMetaAnnotationWithParagraph(body),
      });
    }
  },
);
