import { createAsyncThunk } from '@reduxjs/toolkit';

import { IAutoPromotionSlotsQuery } from 'sp-redux/slices/services/types';

import { doFetch } from 'utils/doFetch';

export const getAutoPromotionSlotsThunk = createAsyncThunk(
  'services/auto/slots',
  async ({
    service_type_id,
    start_date,
    art_id,
    art_type,
  }: IAutoPromotionSlotsQuery) => {
    const apiPointName = 'getAutoPromotionSlots';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      queryParams: { service_type_id, start_date, art_id, art_type },
    });
  },
);
