import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export interface IPublisherReadingStatisticsThunkProps {
  date_from?: string;
  date_to?: string;
  context?: GetServerSidePropsContext;
  limit?: number;
  offset?: number;
  person_id?: number;
  order_by?: string;
  art_id?: number;
}

export const getPublisherReadingStatisticsThunk = createAsyncThunk(
  'statistics/getPersonRoyaltyReportByArt',
  async ({
    date_from,
    date_to,
    person_id,
    context,
    limit,
    offset,
    order_by,
    art_id,
  }: IPublisherReadingStatisticsThunkProps) => {
    const apiPointName = 'getPersonRoyaltyReportByArt';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      queryParams: {
        date_from,
        date_to,
        person_id,
        limit,
        offset,
        order_by,
        art_id,
      },
      context,
    });
  },
);
