import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { filtersGroup } from 'components/complex/FiltersModerator/constans';

import type { IHydrateAction } from 'sp-redux/index';
import type {
  EModerationBookTabs,
  IAllQueueModerationResponseTypes,
  IAllQueueStateType,
  IAutocompletePrepareTypes,
  IPrepareSetFilterTypes,
} from 'sp-redux/slices/moderator/types';
import { getCountersBooksThunk } from 'sp-redux/thunks/moderation/getCountersBooks';
import { getModerationBooksThunk } from 'sp-redux/thunks/moderation/getModerationBooks';
import { getModerationProfilesThunk } from 'sp-redux/thunks/moderation/getModerationProfiles';
import { getModeratorsThunk } from 'sp-redux/thunks/moderation/getModerators';
import { getProfilesThunk } from 'sp-redux/thunks/moderation/getProfiles';
import { takingBooksMyQueueThunk } from 'sp-redux/thunks/moderation/takingBooksMyQueue';
import { takingProfileMyQueueThunk } from 'sp-redux/thunks/moderation/takingProfileMyQueue';

import { initialState } from './constants';

const ModerationSlice = createSlice({
  name: 'moderation',
  initialState,
  reducers: {
    setPage: (state: IAllQueueStateType, action) => {
      state.page = action.payload;
    },
    setTabName: (state: IAllQueueStateType, action) => {
      state.tabName = action.payload;
    },

    setFilterState: (
      state: IAllQueueStateType,
      action: PayloadAction<IPrepareSetFilterTypes>,
    ) => {
      const { groupName, id, checked } = action.payload;

      state.filtersCheckBoxGroup[groupName][id].checked = checked;
    },

    setFilterPostmoderationSwitcherState: (
      state: IAllQueueStateType,
      action,
    ) => {
      const { checked } = action.payload;

      state.filtersSwitchers.postmoderation = checked;
    },

    setFilterPlagiarySwitcherState: (state: IAllQueueStateType, action) => {
      const { checked } = action.payload;

      state.filtersSwitchers.plagiary = checked && 'plagiary';
    },

    setModerationId: (
      state: IAllQueueStateType,
      action: PayloadAction<number>,
    ) => {
      state.moderationId = action.payload;
    },

    setAutocompleteFilterState: {
      reducer: (
        state: IAllQueueStateType,
        action: PayloadAction<IAutocompletePrepareTypes>,
      ) => {
        const { groupName, group } = action.payload;
        state.filtersAutocompleteGroup[groupName] = group;
      },
      prepare: (groupName: string, group: string[]) => {
        return { payload: { groupName, group } };
      },
    },
    resetFilterState: (state: IAllQueueStateType) => {
      state.filtersCheckBoxGroup = filtersGroup;
      state.filtersAutocompleteGroup.mailModerators = [];
      state.filtersAutocompleteGroup.mailProfiles = [];
    },
  },

  extraReducers: builder => {
    builder.addCase(getModerationBooksThunk.fulfilled, (state, action) => {
      state.isFetched = true;
      state.isFetching = false;

      const { pagination } = action.payload;
      const result = action.payload
        .result as IAllQueueModerationResponseTypes[];
      const apiPointName = action.payload.apiPointName as EModerationBookTabs;
      const arr =
        state[apiPointName].result === null || !pagination?.previous_page.length
          ? []
          : state[apiPointName].result;

      state[apiPointName].pagination = pagination;
      state[apiPointName].result = [...(arr || []), ...result];
    });

    builder.addCase(getModerationProfilesThunk.fulfilled, (state, action) => {
      state.isFetched = true;
      state.isFetching = false;

      const { apiPointName, result = [], pagination } = action.payload;
      const arr =
        state[apiPointName].result === null || !pagination?.previous_page.length
          ? []
          : state[apiPointName].result;

      state[apiPointName].pagination = pagination;
      state[apiPointName].result = [...(arr || []), ...result];
    });

    builder.addCase(
      getModerationProfilesThunk.pending,
      (state: IAllQueueStateType) => {
        state.isFetching = true;
        state.isFilterFetching = true;
      },
    );

    builder.addCase(
      getProfilesThunk.fulfilled,
      (state: IAllQueueStateType, action) => {
        state.isFetched = true;
        state.isFetching = false;

        if (
          action.payload.errors.length === 0 &&
          Array.isArray(action.payload.result.data)
        ) {
          state.profiles = [...state.profiles, ...action.payload.result.data];
        }
        if (
          action.payload.errors.length === 0 &&
          Object.keys(action.payload.result.meta).length > 0
        ) {
          state.metaProfiles = action.payload.result.meta;
        }
      },
    );

    builder.addCase(getProfilesThunk.pending, (state: IAllQueueStateType) => {
      state.isFetching = true;
    });

    builder.addCase(
      getModeratorsThunk.fulfilled,
      (state: IAllQueueStateType, action) => {
        state.isFetched = true;
        state.isFetching = false;

        if (
          action.payload.errors.length === 0 &&
          Array.isArray(action.payload.result.data)
        ) {
          state.moderators = [
            ...state.moderators,
            ...action.payload.result.data,
          ];
        }
        if (
          action.payload.errors.length === 0 &&
          Object.keys(action.payload.result.meta).length > 0
        ) {
          state.metaModerators = action.payload.result.meta;
        }
      },
    );

    builder.addCase(getModeratorsThunk.pending, (state: IAllQueueStateType) => {
      state.isFetching = true;
    });

    builder.addCase(
      getCountersBooksThunk.fulfilled,
      (state: IAllQueueStateType, action) => {
        state.isFetched = true;
        state.isFetching = false;

        state.counters = action.payload.result;
      },
    );

    builder.addCase(
      getCountersBooksThunk.pending,
      (state: IAllQueueStateType) => {
        state.isFetching = true;
      },
    );

    builder.addCase(
      takingBooksMyQueueThunk.fulfilled,
      (state: IAllQueueStateType, action) => {
        state.isFetched = true;
        state.isFetching = false;

        state.errors = action.payload.errors;
      },
    );

    builder.addCase(
      takingBooksMyQueueThunk.pending,
      (state: IAllQueueStateType) => {
        state.isFetching = true;
      },
    );

    builder.addCase(
      takingProfileMyQueueThunk.fulfilled,
      (state: IAllQueueStateType, action) => {
        state.isFetched = true;
        state.isFetching = false;

        state.errors = action.payload.errors;
      },
    );

    builder.addCase(
      takingProfileMyQueueThunk.pending,
      (state: IAllQueueStateType) => {
        state.isFetching = true;
      },
    );
    builder.addCase(
      HYDRATE,
      (
        state: IAllQueueStateType,
        action: IHydrateAction<IAllQueueStateType>,
      ) => {
        return action.payload.moderation;
      },
    );
  },
});

export const {
  setPage,
  setTabName,
  setFilterState,
  setAutocompleteFilterState,
  setFilterPostmoderationSwitcherState,
  setFilterPlagiarySwitcherState,
  resetFilterState,
  setModerationId,
} = ModerationSlice.actions;

export const moderationReducer = ModerationSlice.reducer;
