import { firebaseConfigProd, firebaseConfigTest } from 'analytics/const';
import type { IPublicRuntimeConfig } from 'app.types';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const {
  analytics: { enable: isAnalyticsEnabled },
}: IPublicRuntimeConfig = publicRuntimeConfig;

const isJestEnv = !!process.env.JEST_WORKER_ID;

export let logFirebaseEvent = (
  name: string,
  eventParams?: Record<string, string | number>,
): void => {
  // Не вызываем, если ранается в Jest
  if (!isJestEnv) {
    // eslint-disable-next-line no-console
    console.log('analytics not working :(', name, eventParams);
  }
};

try {
  if (typeof window !== 'undefined' && !isJestEnv) {
    const app = initializeApp(
      isAnalyticsEnabled ? firebaseConfigProd : firebaseConfigTest,
    );
    const firebaseAnalytics = getAnalytics(app);

    logFirebaseEvent = (
      name: string,
      eventParams?: Record<string, string | number>,
    ): void => {
      // eslint-disable-next-line no-console
      console.log('log firebase event ', name, eventParams);
      logEvent(firebaseAnalytics, name, eventParams);
    };
  }
} catch (err: Error | unknown) {
  if (err instanceof Error) {
    // we skip the "already exists" message which is
    // not an actual error when we're hot-reloading
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (!/already exists/.test(err.message)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      // eslint-disable-next-line no-console
      console.error('Firebase initialization error', err.stack);
    }
  } else {
    // eslint-disable-next-line no-console
    console.error('Firebase initialization error', err);
  }
}
