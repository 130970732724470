import { createSlice } from '@reduxjs/toolkit';
import { IApiResponseErrorField } from 'app.types';
import {
  IAchievementRewardsDataType,
  IAchievementProgressDataType,
} from 'modules/achievements/types';
import { HYDRATE } from 'next-redux-wrapper';

import { IHydrateAction } from 'sp-redux';
import { getAchievementProgressDataThunk } from 'sp-redux/thunks/achievements/getAchievementProgressData';
import { getAchievementProgressDataForMetricThunk } from 'sp-redux/thunks/achievements/getAchievementProgressDataForMetric';
import { getAchievementRewardsDataThunk } from 'sp-redux/thunks/achievements/getAchievementRewardsData';

interface IAchievementProgressState {
  items: IAchievementProgressDataType[] | null;
  isFetching: boolean;
  isFetched: boolean;
  errors: IApiResponseErrorField[] | null;
}

const progressInitialState: IAchievementProgressState = {
  items: null,
  isFetching: false,
  isFetched: false,
  errors: [],
};

const achievementProgressData = createSlice({
  name: 'achievementProgressData',
  initialState: progressInitialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAchievementProgressDataThunk.pending, state => {
      state.isFetching = true;
      state.errors = null;
    });
    builder.addCase(
      getAchievementProgressDataThunk.fulfilled,
      (state, action) => {
        state.isFetching = false;
        state.isFetched = true;

        state.errors = action.payload.errors;

        if (state.errors.length === 0) {
          state.items = action.payload.result;
        }
      },
    );
    builder.addCase(
      HYDRATE,
      (state, action: IHydrateAction<IAchievementProgressState>) => {
        return action.payload.achievementProgressData;
      },
    );
  },
});

export const achievementProgressDataReducer = achievementProgressData.reducer;

interface IAchievementRewardsState {
  items: IAchievementRewardsDataType[] | null;
  isFetching: boolean;
  errors: IApiResponseErrorField[] | null;
}

const rewardsInitialState: IAchievementRewardsState = {
  items: null,
  isFetching: false,
  errors: [],
};

const achievementRewardsData = createSlice({
  name: 'achievementRewardsData',
  initialState: rewardsInitialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAchievementRewardsDataThunk.pending, state => {
      state.isFetching = true;
      state.errors = null;
    });
    builder.addCase(
      getAchievementRewardsDataThunk.fulfilled,
      (state, action) => {
        state.isFetching = false;

        state.errors = action.payload.errors;

        if (state.errors.length === 0) {
          state.items = action.payload.result;
        }
      },
    );
    builder.addCase(
      HYDRATE,
      (state, action: IHydrateAction<IAchievementRewardsState>) => {
        return action.payload.achievementRewardsData;
      },
    );
  },
});

export const achievementRewardsDataReducer = achievementRewardsData.reducer;

interface IAchievementProgressForSalesState {
  item: IAchievementProgressDataType | null;
  isFetching: boolean;
  errors: IApiResponseErrorField[] | null;
}

const progressForSalesInitialState: IAchievementProgressForSalesState = {
  item: null,
  isFetching: false,
  errors: [],
};

const achievementProgressDataForMetric = createSlice({
  name: 'achievementProgressDataForMetric',
  initialState: progressForSalesInitialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAchievementProgressDataForMetricThunk.pending, state => {
      state.isFetching = true;
      state.errors = null;
    });
    builder.addCase(
      getAchievementProgressDataForMetricThunk.fulfilled,
      (state, action) => {
        state.isFetching = false;

        state.errors = action.payload.errors;

        if (state.errors.length === 0) {
          state.item = action.payload.result;
        }
      },
    );
    builder.addCase(
      HYDRATE,
      (state, action: IHydrateAction<IAchievementProgressForSalesState>) => {
        return action.payload.achievementProgressDataForMetric;
      },
    );
  },
});

export const achievementProgressDataForMetricReducer =
  achievementProgressDataForMetric.reducer;
