import type { IApiResponseErrorField, IPaginationType } from 'app.types';
import type { TPgRateAgeType } from 'entities/Age/types';
import { EAudioBookFormat, ETTSVoiceName } from 'entities/AudioBook/constants';
import type { EBookState } from 'entities/Book/constants';
import { ELicence } from 'entities/BookCard/constants';
import type { IChapter } from 'entities/Chapter/types';
import type { IBookCoverInfo } from 'entities/Cover/types';
import type { IEmployee, IEmployeeInRequest } from 'entities/Employee/types';
import type { IBookFile } from 'entities/File/types';
import type { IGenre } from 'entities/Genre/types';
import { IBookVersions } from 'entities/Moderation/types';
import type { ITag } from 'entities/Tag/types';
import type { IFetchArtReviewsResponse } from 'modules/comments/helpers/fetchArtReviews';

import type { ETemplatesSelector } from 'components/SharedComponents/TemplatesSelector/types';
import type { IAutocompleteOptionType } from 'components/UIKit/AutocompleteSelect';
import type { IModerationBookHistoryItem } from 'components/complex/ModerationHistory/types';

import type { EBookModerationState } from 'sp-redux/slices/moderator/constants';
import type { ETabType } from 'sp-redux/slices/ui/types';

import type { IModerationBookInfo } from '../moderator/types';

export interface ICharactersCountType {
  characters_number: number;
  processing_status: string;
}

export interface IModerationComment {
  moderation_book_id?: number;
  comment: string;
  comment_template_id?: number;
}

export interface IBookCommentsResultType extends IModerationComment {
  comment_template: {
    id: number;
    title: string;
    tab: ETabType;
    comment: string;
  };
}

export interface IBookCommentsType {
  comments: IBookCommentsResultType[];
  activeComments: TActiveCommentsType;
}

export type TActiveCommentsType = Record<number, string>;

export interface ICoverDocumentResponseType {
  content: string;
  link: string;
  file_name: string;
  id: number;
  uuid: string;
}

export interface IBookMetaType {
  id: number;
  title: string;
  annotation: string;
  tags: IAutocompleteOptionType[];
  genres: IAutocompleteOptionType[];
  age: TPgRateAgeType | null;
  obscene_language: boolean | null;
  authors_sheets: null | number;
  update_period: null | number;
  date_written: null | number;
  completion_date: null | number;
  employees: IEmployee[];
  with_printed: boolean;
  file: IBookFile | null;
  owner: null | number;
  price: null | number;
  can_use_draft_license: boolean;
  amnesty_draft_date: null | string;
  can_edit: boolean;
  book_status: EBookState;
  edition_block_reason: null | string;
  is_published: boolean;
  uuid: string;
  days_for_print_publication: number;
  characters_number: null | number;
  processing_status: EPublicationState;
  completion_date_max: string;
  completion_date_min: string;
  file_content_size: number;
  download_date: string;
  is_draft_book: boolean;
  update_to: string | null;
  is_owner_user: boolean;
  // Дата последней публикации. Читать как “дата последнего обновления черновика”
  last_publication_date: number | null;
  foul_words: number;
  is_chapter_draft?: boolean | null;
  lgbt_mark: boolean;
  // служебное поле, с бэка приходит только lgbt_mark
  isLgbtRulesAccepted?: boolean;
  has_discounts: boolean;
  min_discount_price: number;
  // сюда записывается ошибка, которая возвращается из хаба при неудачной публикации
  publication_error?: string;
  litres_art_id: number;
  rating: Partial<IRating>;
  is_imported: boolean;
  create_audiobook: boolean | null;
  audiobook_license: ELicence | null;
  audiobook_gender_voice: ETTSVoiceName | null;
  audiobook_glossary: string | null;
  is_overdue: boolean;
  is_overdue_and_locked: boolean;
  is_on_postmoderation_and_published: boolean;
  is_create_audio: boolean;
  is_free_fanfic: boolean;
  is_free_fanfic_license_agreement_accepted: boolean;
  is_tts_available: boolean;
  is_moderated_audio_available: boolean;
  is_phonogram_available: boolean;
  is_reader_available: boolean;
  is_free_reader_available: boolean;
  audiobook_type: EAudioBookFormat | null;
  recommended_audiobook_type: EAudioBookFormat | null;
}

export interface IServerBookMetaType
  extends Omit<IBookMetaType, 'tags' | 'genres'> {
  nearest_chapter_publication_date: number | null;
  publication_error?: string;
  tags: ITag[];
  genres: IGenre[];
  is_on_postmoderation_and_published: boolean;
  is_free_fanfic: boolean;
  is_free_fanfic_license_agreement_accepted: boolean;
}

export type TEditableBookMetaType = {
  title?: string | undefined;
  annotation?: string | undefined;
  genres?: IAutocompleteOptionType[] | undefined;
  tags?: IAutocompleteOptionType[] | undefined;
  obscene_language?: boolean | undefined | null;
  age?: number | undefined | null;
  employees?: IEmployee[];
  lgbt_mark?: boolean;
  isLgbtRulesAccepted?: boolean;
  is_free_fanfic_license_agreement_accepted?: boolean;
} & Partial<IEditableBookLicenseMeta>;

export interface IEditableBookLicenseMeta {
  owner: null | number;
  price: null | number;
}

export interface ISavableBookMetaType
  extends Omit<IBookMetaType, 'genres' | 'tags' | 'employees'> {
  genres?: (string | number)[];
  tags?: (string | number)[];
  employees?: IEmployeeInRequest[];
}

export interface IBookMetaStateType {
  isFetched: boolean;
  isFetching: boolean;
  meta: Partial<IBookMetaType>;
  oldMeta?: TEditableBookMetaType;
  employees: IEmployee[];
  errors: IApiResponseErrorField[] | null;
  bookComments: Partial<IBookCommentsType>;
  moderationBookMeta: Partial<IModerationBookInfo>;
  cover: Partial<IBookCoverInfo>;
  isPublicationSubmitted: boolean;
  // Костыльный тип, для того, чтобы проверять менял ли юзер обложку и учитывать при локе кнопки публикации
  isCoverChanged: boolean;
  // Показываем лоадер на обложке, пока она перегенерируется
  isCoverLoading: boolean;
  // Костыльный тип для того же с главами
  isChaptersCountChanged: boolean;
  moderationBookHistory: IModerationBookHistoryItem[];
  // Странное поле, чтобы при сохранении подсветить, что человек не принимает гей-правила
  hasLgbtConflicts: boolean;
  chapters: IChapter[];
  discounts: IDiscountListType;
  reviews: IFetchArtReviewsResponse;
  moderationSnapshots: IBookVersions | null;
}

export interface IGroupedOption {
  label: string;
  options: IAutocompleteOptionType[];
}

export enum EPublicationState {
  preparingPublication = 'preparingPublication',
  queueModeration = 'queueModeration',
  moderation = 'moderation',
  returned = 'returned',
  unpublishedChanges = 'unpublishedChanges',
  published = 'published',
  removedPublication = 'removedPublication',
  overdue = 'overdue',
  readyCreate = 'readyCreate',
  applicationRejected = 'applicationRejected',
  applicationConsideration = 'applicationConsideration',
  inProduction = 'inProduction',
}

export type TTemplatesType = 'top' | 'center' | 'bottom';

export enum EFontStyle {
  regular = 'regular',
  bold = 'bold',
}

// интерфейс описания jsona с обложкой
export interface IBookCoverParams {
  image: {
    scale: number;
    zoom: number;
    top: number;
    left: number;
    randomBgIdx: number;
    height: number;
  };
  title: {
    visible: boolean;
    top: number;
    left: number;
    fontStyle: EFontStyle;
    fontSize: number;
    isBgVisible: boolean;
    bgColor: string;
    fill: string;
    template: ETemplatesSelector;
    height: number;
  };
  name: {
    visible: boolean;
    top: number;
    left: number;
    fontStyle: EFontStyle;
    fontSize: number;
    isBgVisible: boolean;
    bgColor: string;
    fill: string;
    height: number;
  };
  adult: {
    top: number;
    left: number;
    visible: boolean;
  };
  obscene: {
    top: number;
    left: number;
    visible: boolean;
  };
}

export interface ISetCommentType {
  id: number;
  comment: string;
}

export interface IRejectedBook {
  is_preorder: boolean;
  status: EBookModerationState;
  moderation_comments: {
    comment_template_id: number;
    comment: string;
  }[];
}

export type TArtType = 'book' | 'audio_book' | 'podcast';

export interface IDiscountsListQuery {
  limit?: number;
  offset?: number;
  art_type: string;
  discount_type: EDiscountsStatuses;
  version: string;
}

export enum EDiscountsStatuses {
  active = 'active',
  created = 'created',
  archived = 'archived',
}

export interface IDiscountListType {
  info: Partial<IDiscountsInfoResponse>;
  active: IDiscountsListResponse;
  archived: IDiscountsListResponse;
  created: IDiscountsListResponse;
  errors: IApiResponseErrorField[] | null;
}

export interface IDiscountsListResponse {
  result: IDiscountsList[];
  pagination: IPaginationType;
}

export interface IDiscountsList {
  id: number;
  discount_percent: number;
  price_old: number;
  price_new: number;
  start_date: number;
  end_date: number;
  pause_end_date?: number;
  status: EDiscountsStatuses;
  sales_sum: number;
  sales_amount: string;
}

export interface IDiscountsInfoResponse {
  agreement_status: string;
  allowed_discount_percents: number[];
  allowed_discount_periods: string;
  id: number;
  is_discount_creation_allowed: boolean;
  min_price: string;
  price: string;
  publication_date: number;
  type: string;
}

export interface IBookDiscountRequest {
  art_type: string | string[];
  date_start: number;
  date_end: number;
  percent: number;
}

export interface IBookDiscountResponse {
  discount_id: number;
}

export interface IUnpublishBookRequest {
  reason: string;
  user_reason?: string;
}

export interface IRating {
  rated_avg: number;
  rated_total_count: number;
}
