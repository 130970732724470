import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export interface IPublisherSalesStatisticsThunkProps {
  date_from?: string;
  date_to?: string;
  context?: GetServerSidePropsContext;
  limit?: number;
  offset?: number;
  order_by?: string;
}

export const getPublisherSalesStatisticsThunk = createAsyncThunk(
  'statistics/getPublisherRoyaltyReportByPerson',
  async ({
    date_from,
    date_to,
    context,
    limit,
    offset,
    order_by,
  }: IPublisherSalesStatisticsThunkProps) => {
    const apiPointName = 'getPublisherRoyaltyReportByPerson';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      queryParams: { date_from, date_to, limit, offset, order_by },
      context,
    });
  },
);
