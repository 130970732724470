import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { TPgRateAgeType } from 'entities/Age/types';
import { EAudioBookFormat, ETTSVoiceName } from 'entities/AudioBook/constants';
import { ELicence } from 'entities/BookCard/constants';
import type { IChapter } from 'entities/Chapter/types';
import { ECoverTypes } from 'entities/Cover/types';
import type { IEmployee } from 'entities/Employee/types';
import type { IBookFile } from 'entities/File/types';
import { HYDRATE } from 'next-redux-wrapper';

import type { IAutocompleteOptionType } from 'components/UIKit/AutocompleteSelect';

import type { IHydrateAction } from 'sp-redux';
import type {
  IBookCommentsResultType,
  IBookMetaStateType,
  IBookMetaType,
  ISetCommentType,
} from 'sp-redux/slices/bookMeta/types';
import { createEmployeeForBookThunk } from 'sp-redux/thunks/bookMeta/createEmployeeForBookThunk';
import { getBookActiveDiscountsThunk } from 'sp-redux/thunks/bookMeta/getBookActiveDiscounts';
import { getBookArchivedDiscountsThunk } from 'sp-redux/thunks/bookMeta/getBookArchivedDiscounts';
import {
  getBookCommentsForUser,
  getBookCommentsThunkForModerator,
} from 'sp-redux/thunks/bookMeta/getBookComments';
import { getBookCoverThunk } from 'sp-redux/thunks/bookMeta/getBookCoverThunk';
import { getBookCreatedDiscountsThunk } from 'sp-redux/thunks/bookMeta/getBookCreatedDiscounts';
import { getBookDiscountsThunk } from 'sp-redux/thunks/bookMeta/getBookDiscounts';
import { getBookMetaThunk } from 'sp-redux/thunks/bookMeta/getBookMeta';
import { getBookModerationHistoryThunk } from 'sp-redux/thunks/bookMeta/getBookModerationHistory';
import { getBookRatingThunk } from 'sp-redux/thunks/bookMeta/getBookRatingThunk';
import { getBookReviewsThunk } from 'sp-redux/thunks/bookMeta/getBookReviewsThunk';
import { getDraftBookChaptersThunk } from 'sp-redux/thunks/bookMeta/getDraftBookChapters';
import { getEmployeesRolesForBookThunk } from 'sp-redux/thunks/bookMeta/getEmployeesRolesForBookThunk';
import { getReviewByIdThunk } from 'sp-redux/thunks/bookMeta/getReviewById';
import { saveBookMetaFieldsThunk } from 'sp-redux/thunks/bookMeta/saveBookMetaFields';
import { sendCommentReplyThunk } from 'sp-redux/thunks/bookMeta/sendCommentReplyThunk';
import { setBookCoverThunk } from 'sp-redux/thunks/bookMeta/setBookCoverThunk';
import { getBookModerationInfo } from 'sp-redux/thunks/moderation/getBookModerationInfo';
import { getModerationSnapshots } from 'sp-redux/thunks/snapshots/getModerationSnapshots';

import { mapToAutocompleteItems } from 'utils/mapToAutocompleteItems';

import { initialState } from './constants';
import type { EPublicationState } from './types';

const BookMetaSlice = createSlice({
  name: 'bookMeta',
  initialState,
  reducers: {
    setTitle: (state, action: PayloadAction<string>) => {
      state.meta.title = action.payload;
    },

    setAnnotation: (state, action: PayloadAction<string>) => {
      state.meta.annotation = action.payload;
    },

    setFile: (state, action: PayloadAction<IBookFile>) => {
      state.meta.file = action.payload;
    },

    setFileName: (state, action: PayloadAction<string>) => {
      if (state.meta.file) {
        state.meta.file.name = action.payload;
      }
    },

    setGenres: (state, action: PayloadAction<IAutocompleteOptionType[]>) => {
      state.meta.genres = action.payload;
    },

    setTags: (state, action: PayloadAction<IAutocompleteOptionType[]>) => {
      state.meta.tags = action.payload;
    },

    setLgbtRulesAccepted: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.meta.age = 18;
      }
      state.meta.isLgbtRulesAccepted = action.payload || false;
    },

    setLgbtMark: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.meta.age = 18;
      }
      state.meta.lgbt_mark = action.payload;
    },

    setLgbtConflicts: (state, action: PayloadAction<boolean>) => {
      state.hasLgbtConflicts = action.payload;
    },

    setObsceneLanguage: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.meta.age = 18;
      }
      state.meta.obscene_language = action.payload;
    },

    setIsChapterDraft: (state, action: PayloadAction<boolean>) => {
      state.meta.is_chapter_draft = action.payload;
    },

    setAge: (state, action: PayloadAction<TPgRateAgeType>) => {
      if (state.meta.obscene_language === true) {
        state.meta.age = 18;
      } else {
        state.meta.age = action.payload;
      }
    },

    setMetaProps: (state, action: PayloadAction<Partial<IBookMetaType>>) => {
      state.meta = {
        ...state.meta,
        ...action.payload,
      };
    },

    updateBookEmployees: (state, action: PayloadAction<IEmployee[]>) => {
      state.meta.employees = action.payload;
    },

    setComment: (state, action: PayloadAction<ISetCommentType>) => {
      if (state.bookComments.activeComments) {
        const isExist =
          typeof state.bookComments.activeComments[action.payload.id] ===
          'string';
        if (isExist) {
          const map = new Map(
            Object.entries(state.bookComments.activeComments),
          );

          map.delete(String(action.payload.id));

          state.bookComments.activeComments = Object.fromEntries(map);
        } else {
          state.bookComments.activeComments[action.payload.id] =
            action.payload.comment || '';
        }
      }
    },

    setTextComment: (state, action: PayloadAction<ISetCommentType>) => {
      if (state.bookComments.activeComments) {
        state.bookComments.activeComments[action.payload.id] =
          action.payload.comment;
      }
    },

    setPublicationSubmitted: (state, action: PayloadAction<boolean>) => {
      state.isPublicationSubmitted = action.payload;
    },

    setIsCoverChanged: (state, action: PayloadAction<boolean>) => {
      state.isCoverChanged = action.payload;
    },

    setIsCoverLoading: (state, action: PayloadAction<boolean>) => {
      state.isCoverLoading = action.payload;
    },

    setIsCoverFromConstructor: (state, action: PayloadAction<boolean>) => {
      state.cover.is_constructor = action.payload;
    },

    setCoverDownloadDate: (state, action: PayloadAction<string>) => {
      state.cover.cover_download_date = action.payload;
    },

    setIsChaptersCountChanged: (state, action: PayloadAction<boolean>) => {
      state.isChaptersCountChanged = action.payload;
    },

    clearActiveDiscounts: state => {
      state.discounts.active.result = [];
    },

    setChapters: (state, action: PayloadAction<IChapter[]>) => {
      state.chapters = action.payload;
    },

    setCreateAudiobook: (state, action: PayloadAction<boolean>) => {
      state.meta.create_audiobook = action.payload;
    },

    setAudiobookLicense: (state, action: PayloadAction<ELicence | null>) => {
      state.meta.audiobook_license = action.payload;
    },

    setAudiobookGenderVoice: (state, action: PayloadAction<ETTSVoiceName>) => {
      state.meta.audiobook_gender_voice = action.payload;
    },

    setAudiobookGlossary: (state, action: PayloadAction<string>) => {
      state.meta.audiobook_glossary = action.payload;
    },

    setOldAge: (state, action: PayloadAction<number>) => {
      if (state.oldMeta?.age || state.oldMeta?.age === 0) {
        state.oldMeta.age = action.payload;
      }
    },

    setAudioType: (state, action: PayloadAction<EAudioBookFormat | null>) => {
      state.meta.audiobook_type = action.payload;
    },

    setCoverType: (state, action: PayloadAction<ECoverTypes>) => {
      state.cover.cover_type = action.payload;
    },

    resetBookMetaToInitial: () => {
      return { ...initialState, isFetched: true };
    },
  },

  extraReducers: builder => {
    builder.addCase(getBookMetaThunk.fulfilled, (state, action) => {
      state.isFetched = true;
      state.isFetching = false;
      const { result } = action.payload;
      if (Object.keys(result).length > 0) {
        const genres = mapToAutocompleteItems(result.genres);
        const tags = mapToAutocompleteItems(result.tags);
        state.meta = {
          ...result,
          annotation: result.annotation.replace(/(\<(\/?[^>]+)>)/g, ''),
          processing_status: result.processing_status as EPublicationState,
          genres,
          tags,
          isLgbtRulesAccepted: result.lgbt_mark || false,
        };
        state.oldMeta = {
          title: result.title,
          annotation: result.annotation,
          genres,
          tags,
          obscene_language: result.obscene_language,
          age: result.age,
          price: result.price,
          owner: result.owner,
          isLgbtRulesAccepted: result.lgbt_mark || false,
        };
      }
    });

    builder.addCase(getBookMetaThunk.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(
      getEmployeesRolesForBookThunk.fulfilled,
      (state, action) => {
        state.employees = action.payload.result;
      },
    );

    builder.addCase(createEmployeeForBookThunk.fulfilled, (state, action) => {
      const newBookEmployee = action.payload.result;
      const creationErrors = action.payload.errors;
      if (creationErrors.length) {
        state.errors = creationErrors;
      }
      if (state.meta.employees && !creationErrors.length) {
        state.meta.employees = [...state.meta.employees, newBookEmployee];
      }
    });

    builder.addCase(getBookCoverThunk.fulfilled, (state, action) => {
      state.cover = action.payload.result || {};
    });

    builder.addCase(setBookCoverThunk.fulfilled, (state, action) => {
      state.cover = action.payload.result || {};
    });

    builder.addCase(getDraftBookChaptersThunk.fulfilled, (state, action) => {
      state.chapters = action.payload.result;
    });

    builder.addCase(
      getBookCommentsThunkForModerator.fulfilled,
      (state, action) => {
        state.isFetched = true;
        if (!Array.isArray(action.payload.result)) {
          state.bookComments.comments = [];
        } else {
          const commentsToSave = action.payload.result
            // eslint-disable-next-line
            .filter(item => !!item.comment_template)
            .reduce((acc: { [key: number]: string }, item) => {
              acc[item.comment_template.id] = item.comment;
              return acc;
            }, {});
          state.bookComments.activeComments = commentsToSave;
        }
      },
    );
    builder.addCase(getBookCommentsForUser.fulfilled, (state, action) => {
      state.isFetched = true;
      if (!Array.isArray(action.payload.result)) {
        state.bookComments.comments = [];
        state.bookComments.activeComments = [];
      } else {
        // Необходимость сохранять уникальность приходящих объектов
        // Из-за нового функционала постмодерации, у нас удваиваются комментарии, если их ставят модераторы, когда передают друг друга
        // Бек проверку на уникальность пока не делает, но сделает в PS-3291
        const uniqueComments: { [key: string]: boolean } = {};

        const commentsToSave = action.payload.result.reduce(
          (acc: IBookCommentsResultType[], item: IBookCommentsResultType) => {
            const key = JSON.stringify(item);
            // eslint-disable-next-line
            if (!!item.comment_template && !uniqueComments[key]) {
              uniqueComments[key] = true;
              acc.push(item);
            }
            return acc;
          },
          [] as IBookCommentsResultType[],
        );

        state.bookComments.comments = commentsToSave;
      }
    });
    builder.addCase(getBookModerationInfo.fulfilled, (state, action) => {
      state.isFetched = true;
      state.moderationBookMeta = action.payload.result;
    });
    builder.addCase(getBookActiveDiscountsThunk.fulfilled, (state, action) => {
      state.isFetched = true;

      if (action.payload.result.length) {
        state.discounts.active.result = [
          ...state.discounts.active.result,
          ...action.payload.result,
        ];
      }

      if (action.payload.pagination) {
        state.discounts.active.pagination = action.payload.pagination;
      }
    });
    builder.addCase(
      getBookArchivedDiscountsThunk.fulfilled,
      (state, action) => {
        if (action.payload.result.length) {
          state.discounts.archived.result = [
            ...state.discounts.archived.result,
            ...action.payload.result,
          ];
        }

        if (action.payload.pagination) {
          state.discounts.archived.pagination = action.payload.pagination;
        }
      },
    );
    builder.addCase(getBookCreatedDiscountsThunk.fulfilled, (state, action) => {
      if (action.payload.result.length) {
        state.discounts.created.result = [
          ...state.discounts.created.result,
          ...action.payload.result,
        ];
      }

      if (action.payload.pagination) {
        state.discounts.created.pagination = action.payload.pagination;
      }
    });
    builder.addCase(
      getBookModerationHistoryThunk.fulfilled,
      (state, action) => {
        state.moderationBookHistory = action.payload.result;
      },
    );

    builder.addCase(getBookRatingThunk.fulfilled, (state, action) => {
      // eslint-disable-next-line
      if (action.payload.payload) {
        state.meta.rating = action.payload.payload.data.rating;
      }
    });

    builder.addCase(sendCommentReplyThunk.fulfilled, (state, action) => {
      // eslint-disable-next-line
      if (action.payload.result) {
        state.reviews.payload.data.map((item, i) => {
          if (item.id === action.payload.result.id) {
            state.reviews.payload.data.splice(i, 1, action.payload.result);
          }
        });
      }
      state.reviews.errors = action.payload.errors;
    });

    builder.addCase(getBookReviewsThunk.fulfilled, (state, action) => {
      state.reviews.payload.data = [
        ...state.reviews.payload.data,
        // eslint-disable-next-line
        ...(action.payload.payload?.data || []),
      ];
      state.reviews.payload.pagination =
        // eslint-disable-next-line
        action.payload.payload?.pagination || {};
    });

    builder.addCase(getBookDiscountsThunk.fulfilled, (state, action) => {
      state.discounts.info = action.payload.result;
    });

    builder.addCase(getReviewByIdThunk.fulfilled, (state, action) => {
      // eslint-disable-next-line
      state.reviews.newReview = action.payload.payload.data || [];
    });

    builder.addCase(getModerationSnapshots.fulfilled, (state, action) => {
      state.moderationSnapshots = action.payload.result;
    });

    // https://jira.mybook.tech/browse/PS-2354
    builder.addCase(
      HYDRATE,
      (state, action: IHydrateAction<IBookMetaStateType>) => {
        const { bookMeta } = action.payload;
        const shouldReturnState =
          state.isFetched &&
          (!bookMeta.meta.id || bookMeta.meta.id === state.meta.id) &&
          // костыль чтобы обновить список скидок при переходе от скидок на книгу к скидкам на аудиокнигу
          bookMeta.discounts.info.type === state.discounts.info.type;
        //костыль для отображения отзывов у аудио
        //отзывы надо положить в отдельный слайс стора!
        if (
          bookMeta.reviews.payload.data.length &&
          bookMeta.reviews.payload.data[0]?.id !==
            state.reviews.payload.data[0]?.id
        ) {
          return action.payload.bookMeta;
        }
        if (shouldReturnState) {
          // сохраняем bookMeta когда уходим со страницы, т.к. нужно отправить данные
          return state;
        }
        // подтягиваем bookMeta если ее нет
        return action.payload.bookMeta;
      },
    );

    builder.addCase(saveBookMetaFieldsThunk.fulfilled, (state, action) => {
      state.meta.is_tts_available = action.payload?.result.is_tts_available;
      state.meta.is_moderated_audio_available =
        action.payload?.result.is_moderated_audio_available;
      state.meta.is_phonogram_available =
        action.payload?.result.is_phonogram_available;
      state.meta.is_reader_available =
        action.payload?.result.is_reader_available;
      state.meta.is_free_reader_available =
        action.payload?.result.is_free_reader_available;
      state.meta.recommended_audiobook_type =
        action.payload?.result.recommended_audiobook_type;
    });
  },
});

export const {
  setTitle,
  setAnnotation,
  setFile,
  setGenres,
  setTags,
  setObsceneLanguage,
  setAge,
  setFileName,
  setMetaProps,
  updateBookEmployees,
  setComment,
  setTextComment,
  setPublicationSubmitted,
  setIsCoverChanged,
  setIsCoverLoading,
  setIsCoverFromConstructor,
  setCoverDownloadDate,
  setLgbtRulesAccepted,
  setLgbtMark,
  setLgbtConflicts,
  clearActiveDiscounts,
  resetBookMetaToInitial,
  setIsChapterDraft,
  setIsChaptersCountChanged,
  setChapters,
  setCreateAudiobook,
  setAudiobookLicense,
  setAudiobookGenderVoice,
  setAudiobookGlossary,
  setOldAge,
  setAudioType,
  setCoverType,
} = BookMetaSlice.actions;

export const bookMetaReducer = BookMetaSlice.reducer;
