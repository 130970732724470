// Сюда пишутся все утилзы связанные с преобразованием текста и HTML
import type { ISavableBookMetaType } from 'sp-redux/slices/bookMeta/types';

import { wrapStringInTags } from './wrapStringInTags';

// Получает мету и возвращает мету, в которой поле annotation завернуто в <p></p>. Такое требование апи \_(ツ)_/¯
export const enrichBookMetaAnnotationWithParagraph = (
  meta: Partial<ISavableBookMetaType>,
): Partial<ISavableBookMetaType> => ({
  ...meta,
  ...(typeof meta.annotation === 'string' && {
    annotation: wrapStringInTags(meta.annotation),
  }),
});
