import { createAsyncThunk } from '@reduxjs/toolkit';

import { IPostAutoPromotionArtRequest } from 'sp-redux/slices/services/types';

import { doFetch } from 'utils/doFetch';

export const postAutoPromotionArtThunk = createAsyncThunk(
  'services/promotion/auto/payment/result',
  async ({
    type_id,
    start_date_time,
    art_info,
  }: IPostAutoPromotionArtRequest) => {
    const apiPointName = 'postAutoPromotionArt';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      body: {
        type_id,
        start_date_time,
        art_info,
      },
    });
  },
);
