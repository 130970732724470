import { createAsyncThunk } from '@reduxjs/toolkit';
import { EMetricTypes } from 'modules/achievements/types';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export interface IAchievementProgressDataForMetricThunkProps {
  metricType: EMetricTypes;
  context?: GetServerSidePropsContext;
}

export const getAchievementProgressDataForMetricThunk = createAsyncThunk(
  'achievements/getAchievementProgressDataForMetric',
  async ({
    metricType,
    context,
  }: IAchievementProgressDataForMetricThunkProps) => {
    const apiPointName = 'getAchievementProgressDataForMetric';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
      urlParams: {
        metricType,
      },
    });
  },
);
